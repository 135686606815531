import React, { useState, useEffect } from "react";

interface City {
  id: number;
  city: string;
}

interface IProps {
  id: string;
  selectedCity: City | null;
  onCityChange: (item: City) => void;
  cities: City[];
  label?: string;
  rightText?: string;
}

const CityDropdown: React.FC<IProps> = ({
  id,
  selectedCity,
  onCityChange,
  cities,
  label,
  rightText,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!selectedCity && cities?.length > 0) {
      onCityChange(cities[0]);
    }
  }, [selectedCity, cities, onCityChange]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && isOpen) {
        setIsOpen(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isOpen]);

  const handleCitySelect = (city: City) => {
    onCityChange(city);
    setIsOpen(false);
  };

  return (
    <div className="form__item">
      <div className="form__item-row">
        {label && (
          <label htmlFor={id} className="form__label">
            {label}
          </label>
        )}
        {rightText && <div className="form__converter">{rightText}</div>}
      </div>
      <button
        className="form__control form-control form-control--transfer"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="form-control__img form-control__img--city">
          <img className="form-control__icon" src="/img/svg/city.svg" alt="" />
        </div>
        <p className="form-control__text">{selectedCity?.city}</p>
        <img
          className="form-control__arrow"
          src="/img/svg/arrow.svg"
          alt="arrow"
        />
      </button>

      {isOpen && (
        <ul className="dropdown-menu">
          {cities?.map((city: City) => (
            <li
              key={city?.city}
              className="dropdown-item"
              onClick={() => handleCitySelect(city)}
            >
              <p className="form-control__text-dropdown">{city?.city}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CityDropdown;
