import React from "react";
import {
  useConfirmTransaction,
  useGetOrder,
  useOpenOrderDispute,
} from "../../services/order.services";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/routes";
import { useTranslation } from "react-i18next";

const TraderPaid: React.FC = () => {
  const orderId = localStorage.getItem("CURRENT_ORDER_ID");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { order } = useGetOrder(orderId!);

  const { mutate: confirmTransaction } = useConfirmTransaction(
    () => {
      localStorage.setItem("ORDER_STATUS", "ORDER_COMPLETED");
    },
    () => {
      toast.error(t("SOMETHING_WENT_WRONG"));
    }
  );

  const { mutate: openOrderDispute } = useOpenOrderDispute(
    () => {
      navigate(ROUTES.ORDER_CHAT.replace(":orderId", orderId!));
      localStorage.setItem("ORDER_STATUS", "DISPUTE");
    },
    () => {
      toast.error("Something went wrong...");
    }
  );

  const handleSubmit = () => {
    confirmTransaction({
      orderId: orderId!,
    });
  };

  const handleOpenDispute = () => {
    if (order?.status === 11) {
      navigate(ROUTES.ORDER_CHAT.replace(":orderId", orderId!));
    } else {
      openOrderDispute(orderId!);
    }
  };

  return (
    <div className="main check">
      <div className="body">
        <div className="check__body">
          <img className="check__logo" src="/img/logo.svg" alt="logo bobster" />

          <p
            className="check__text"
            dangerouslySetInnerHTML={{
              __html: t("TRADER_PAID.TITLE", {
                br: "<br />",
              }),
            }}
          />
          <div className="check__actions">
            <a href="#" className="check__link">
              {t("ORDER_RECEIPT")}
            </a>
            <button className="check__btn-pdf">
              <img src="/img/svg/pdf.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
      <div className="footer">
        <p
          className="check__important"
          dangerouslySetInnerHTML={{
            __html: t("CONFIRM_TRANSFER", {
              br: "<br />",
            }),
          }}
        />
        <div className="check__buttons">
          <button
            onClick={handleOpenDispute}
            className="button button--blue-border"
          >
            {t("TRANSFER_NO_RECEIVED")}
          </button>
          <button onClick={handleSubmit} className="button">
            {t("TRANSFER_RECEIVE")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TraderPaid;
