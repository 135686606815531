import React, { useEffect } from "react";
import { useTelegram } from "../../providers/TelegramProvider";
import { Link, useNavigate } from "react-router-dom";
import {
  useDeletePaymentMethod,
  useGetPaymentMethods,
} from "../../services/paymentMethod.services";
import { ROUTES } from "../../routes/routes";
import { IPaymentMethod } from "../../models/paymentMethod.model";
import toast from "react-hot-toast";
import { TFunction } from "../../models/translation";
import { useTranslation } from "react-i18next";

const SettingCards: React.FC = () => {
  const { paymentMethods, refetch } = useGetPaymentMethods();
  const { t } = useTranslation();
  const { mutate: deletePaymentMethod } = useDeletePaymentMethod(
    () => {
      refetch();
      toast.success(t("CARD_DELETED.SUCCESS"));
    },
    (error) => {
      toast.error(error);
    }
  );
  const { webApp } = useTelegram();
  const navigate = useNavigate();

  const maskCardNumber = (number: string): string => {
    const cleanNumber = number.replace(/\s+/g, "");
    if (cleanNumber.length === 16) {
      return cleanNumber.replace(
        /^(\d{4})(\d{4})(\d{4})(\d{4})$/,
        "$1 **** **** $4"
      );
    }
    return number;
  };

  const handleDeletePaymentMethod = (id: number) => {
    deletePaymentMethod(id);
  };

  useEffect(() => {
    if (!webApp) return;

    const handleBackButtonClick = () => {
      navigate(-1);
    };

    webApp.BackButton.onClick(handleBackButtonClick);
    webApp.BackButton.show();

    return () => {
      webApp.BackButton.offClick(handleBackButtonClick);
      webApp.BackButton.hide();
    };
  }, [webApp, navigate]);

  const creditCards = paymentMethods?.filter(
    (method: IPaymentMethod) => method.type === "CreditCard"
  );

  return (
    <div className="main cards">
      <div className="body">
        <h1 className="cards__title title mb-title">{t("MY_CARDS")}</h1>
        <ul className="cards__items">
          {creditCards?.map((method: IPaymentMethod) => {
            const methodDetails = JSON.parse(method.details);
            const maskedNumber = methodDetails?.Number
              ? maskCardNumber(methodDetails.Number)
              : "";

            return (
              <li key={method.id} className="cards__item">
                <label htmlFor="" className="cards__label">
                  {t("CARD_CURRENCY.LABEL", {
                    currency: methodDetails?.Currency,
                  })}
                </label>
                <div className="cards__body">
                  <img
                    className="cards__icon"
                    src={
                      methodDetails.Type
                        ? `/img/card-icons/${methodDetails.Type}.svg`
                        : "/img/card-icons/default-card.svg"
                    }
                    alt=""
                  />
                  <div className="cards__info">
                    <div className="cards__code">{maskedNumber}</div>
                    <div className="cards__term"></div>
                  </div>
                  <button
                    onClick={() => handleDeletePaymentMethod(method.id)}
                    className="cards__btn-del"
                  >
                    <svg className="cards__btn-del-icon">
                      <use xlinkHref="/img/sprite.svg#trash"></use>
                    </svg>
                  </button>
                </div>
              </li>
            );
          })}
        </ul>
        <Link
          to={ROUTES.NEW_CARD}
          className="cards__add-btn button button--blue-border"
        >
          {t("ADD")}
          <img className="cards__add-btn-icon" src="/img/svg/add.svg" alt="" />
        </Link>
      </div>
    </div>
  );
};

export default SettingCards;
