import React, { useState, useEffect } from "react";
import { ICountry } from "../../../models/countries.services";

interface Country {
  id: number;
  country: string;
}

interface IProps {
  id: string;
  selectedCountry: Country | null;
  onCountryChange: (item: Country) => void;
  countries: Country[];
  label?: string;
  rightText?: string;
}

const CountryDropdown: React.FC<IProps> = ({
  id,
  selectedCountry,
  onCountryChange,
  countries,
  label,
  rightText,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!selectedCountry && countries?.length > 0) {
      onCountryChange(countries[0]);
    }
  }, [selectedCountry, countries, onCountryChange]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isOpen && !(event.target as Element).closest(".form__item")) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isOpen]);

  const handleCountrySelect = (country: Country) => {
    onCountryChange(country);
    setIsOpen(false);
  };

  return (
    <div className="form__item">
      <div className="form__item-row">
        {label && (
          <label htmlFor={id} className="form__label">
            {label}
          </label>
        )}
        {rightText && <div className="form__converter">{rightText}</div>}
      </div>
      <button
        className="form__control form-control form-control--transfer"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <p className="form-control__text">{selectedCountry?.country}</p>
        <img
          className="form-control__arrow"
          src="/img/svg/arrow.svg"
          alt="arrow"
        />
      </button>

      {isOpen && (
        <ul className="dropdown-menu">
          {countries?.map((country: Country) => (
            <li
              key={country?.country}
              className="dropdown-item"
              onClick={() => handleCountrySelect(country)}
            >
              <p className="form-control__text-dropdown">{country?.country}</p>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CountryDropdown;
