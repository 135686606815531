import React, { useEffect, useState, useCallback, useRef } from "react";
import { useGetOrder } from "../../services/order.services";
import Modal from "../../components/Modal";
import { useTranslation } from "react-i18next";
import { TFunction } from "../../models/translation";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes/routes";

const OrderTransferWaiting: React.FC = () => {
  const orderId = localStorage.getItem("CURRENT_ORDER_ID");
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number | null>(null);
  const { order, refetch, isLoading } = useGetOrder(orderId!);
  const warningSound = new Audio("/sounds/warning-sound.mp3");
  const { t } = useTranslation();

  // Храним идентификатор интервала
  const intervalIdRef = useRef<NodeJS.Timeout | null>(null);

  // Очищаем интервал при размонтировании компонента
  useEffect(() => {
    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  const calculateRemainingTime = useCallback(
    (startTime: number, duration: number) => {
      const elapsed = Date.now() - startTime;
      return Math.max(duration - elapsed, 0);
    },
    []
  );

  const startTimer = useCallback(
    (startTime: number, duration: number) => {
      // Очищаем предыдущий интервал, если он существует
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }

      const endTime = startTime + duration;
      const updateTime = () => {
        const remainingTime = calculateRemainingTime(startTime, duration);
        setTimeLeft(remainingTime);

        if (remainingTime <= 0) {
          if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
          }
        }
      };

      updateTime();
      intervalIdRef.current = setInterval(updateTime, 1000);
    },
    [calculateRemainingTime]
  );

  useEffect(() => {
    if (order && order.customerSentAt) {
      const startTime = new Date(
        order.traderReceivedAt || order.customerSentAt
      ).getTime();
      const duration = 15 * 60 * 1000; // 15 минут
      const remainingTime = calculateRemainingTime(startTime, duration);

      if (remainingTime > 0) {
        startTimer(startTime, duration);
      } else {
        setTimeLeft(0);
      }
    }
  }, [order, calculateRemainingTime, startTimer]);

  useEffect(() => {
    const refetchIntervalId = setInterval(() => {
      refetch();

      if (order?.status === 4 && order.traderReceivedAt) {
        const startTime = new Date(order.traderReceivedAt).getTime();
        const duration = 5 * 60 * 1000; // 5 минут
        const remainingTime = calculateRemainingTime(startTime, duration);

        if (remainingTime > 0) {
          warningSound.play();
          setIsNotificationModalOpen(true);
          startTimer(startTime, duration);
        }

        clearInterval(refetchIntervalId);
      }

      if (order?.status === 5 && order.traderPaidAt) {
        localStorage.setItem("ORDER_STATUS", "TRADER_PAID");
      }

      if (order?.status === 11) {
        localStorage.setItem("ORDER_STATUS", "DISPUTE");
      }
    }, 10000);

    return () => clearInterval(refetchIntervalId);
  }, [order, refetch, calculateRemainingTime, startTimer]);

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  const handleCloseNotificationModal = () => setIsNotificationModalOpen(false);

  return (
    <div className="main client-order-control">
      <div className="body">
        <div className="client-order-control__info order-control-info">
          <h1 className="order-control-info__title title">
            {t("ORDER_TRANSFER_WAITING.TITLE")}
          </h1>
          <p className="order-control-info__text">
            {t("ORDER_TRANSFER_WAITING.ORDER_CREATED")}{" "}
            <span className="order-control-info__text--order">
              #{order?.orderId}
            </span>
          </p>
        </div>
        <div className="client-order-control__time transaction-detail-time">
          <p className="transaction-detail-time__text">
            {t("ORDER_TRANSFER_WAITING.TIME_REMAINING")}
          </p>
          <div className="transaction-detail-time__timer">
            {timeLeft !== null
              ? formatTime(timeLeft)
              : t("ORDER_TRANSFER_WAITING.LOADING")}
          </div>
        </div>
        <div className="client-order-control__status order-control-status">
          <label htmlFor="" className="order-control-status__label">
            {t("ORDER_TRANSFER_WAITING.STATUS_LABEL")}
          </label>
          <div className="order-control-status__value">
            {t("ORDER_TRANSFER_WAITING.STATUS_IN_PROGRESS")}
          </div>
        </div>
        <div className="client-order-control__summary transaction-detail-summary">
          <div className="transaction-detail-summary__amount">
            {order?.amount} {order?.customerCurrency.code}
          </div>
          <p className="transaction-detail-summary__text">
            {t("ORDER_TRANSFER_WAITING.CARD_TRANSFER")}{" "}
            <span>
              {JSON.parse(order?.paymentMethod.details || "{}")?.Number}
            </span>
          </p>
        </div>
      </div>
      <div className="client-order-control__footer footer">
        <button
          onClick={() => refetch()}
          disabled={isLoading}
          type="submit"
          className="button"
        >
          {isLoading
            ? t("ORDER_TRANSFER_WAITING.LOADING")
            : t("ORDER_TRANSFER_WAITING.REFRESH_STATUS")}
        </button>
        <div className="client-order-control__actions">
          <Link
            to={ROUTES.ORDER_CHAT.replace(":orderId", orderId!)}
            className="client-order-control__btn button button--gray-border"
          >
            {t("ORDER_TRANSFER_WAITING.CHAT")}
          </Link>
        </div>
      </div>

      <Modal
        isOpen={isNotificationModalOpen}
        onClose={handleCloseNotificationModal}
      >
        <div className="order-warning-modal">
          <h1 className="order-warning-header">
            {t("ORDER_TRANSFER_WAITING.NOTIFICATION.TITLE")}
          </h1>
          <p className="order-warning-content">
            {t("ORDER_TRANSFER_WAITING.NOTIFICATION.CONTENT")}
          </p>
          <div className="modal-actions">
            <button onClick={handleCloseNotificationModal} className="button">
              {t("ORDER_TRANSFER_WAITING.NOTIFICATION.CONTINUE")}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OrderTransferWaiting;
