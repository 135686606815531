import { useQuery } from "@tanstack/react-query";
import authApi from "../config/authApi.config";
import { ICountry } from "../models/countries.services";

const getCountries = async () => {
  const response = await authApi.get("/addresses/countries");
  return response.data as any;
};

const getCities = async (countryId: string) => {
  const response = await authApi.get(
    `/addresses/cities?countryId=${countryId}`
  );
  return response.data as any;
};

const getAddresses = async () => {
  const response = await authApi.get("/addresses");
  return response.data as any;
};

export const useGetCountries = () => {
  const { data } = useQuery({
    queryKey: ["countries"],
    queryFn: getCountries,
  });

  return { countries: data };
};

export const useGetCities = (countryId: string) => {
  const { data, refetch } = useQuery({
    queryKey: ["cities", countryId],
    queryFn: () => getCities(countryId),
    enabled: !!countryId,
  });

  return { cities: data, refetch };
};

export const useGetAddresses = () => {
  const { data } = useQuery({
    queryKey: ["addresses"],
    queryFn: getAddresses,
  });

  return { addresses: data };
};
