import React, { useEffect, useMemo } from "react";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes/routes";
import {
  useGetCurrencies,
  useGetLanguages,
} from "../../services/settings.service";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropdown from "../../components/Form/Dropdown";
import LanguageDropdown from "../../components/Form/LanguageDropdown";
import { useGetMe, useUpdateUser } from "../../services/user.services";
import toast from "react-hot-toast";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useGetLanguageFile } from "../../services/languages.services";
import { useGetExchangePairs } from "../../services/exchangePairs.services";

const SettingsFormSchema = yup.object().shape({
  myCurrencyId: yup.number().required("FORM.TRANSFER_CURRENCY.REQUIRED"),
  languageId: yup.number().required("FORM.LANGUAGE_INTERFACE.REQUIRED"),
  receiverCurrencyId: yup.number().required("FORM.PROFILE_CURRENCY.REQUIRED"),
});

const Settings: React.FC = () => {
  const { t } = useTranslation();
  const { me } = useGetMe();
  const { currencies } = useGetCurrencies();
  const { languages } = useGetLanguages();

  const { exchangePairs } = useGetExchangePairs();

  const {
    handleSubmit,
    setValue,
    control,
    watch,
    formState: { isDirty },
  } = useForm({
    resolver: yupResolver(SettingsFormSchema),
    defaultValues: {
      myCurrencyId: me?.myCurrency?.id,
      languageId: me?.language?.id || languages?.[0]?.id,
      receiverCurrencyId: me?.receiverCurrency?.id,
    },
  });

  const selectedLanguageId = watch("languageId");
  const myCurrencyId = watch("myCurrencyId");
  const receiverCurrencyId = watch("receiverCurrencyId");
  const selectedLanguage = languages?.find(
    (lang: any) => lang.id === selectedLanguageId
  );

  const { languageFile } = useGetLanguageFile(selectedLanguage?.file_path);

  const { mutate: updateUser, isPending } = useUpdateUser(
    () => {
      toast.success(t("SETTINGS_SAVED"));
      if (selectedLanguage) {
        i18n.addResourceBundle(
          selectedLanguage.code,
          "translation",
          languageFile,
          true,
          true
        );
        i18n.changeLanguage(selectedLanguage.code);
        localStorage.setItem("LANGUAGE", selectedLanguage.id);
      }
    },
    (error) => {
      toast.error(error);
    }
  );

  useEffect(() => {
    if (me && languages && currencies) {
      setValue("myCurrencyId", me?.myCurrency?.id || currencies[1]?.id);
      setValue("languageId", me?.language?.id || languages[0]?.id);
      setValue(
        "receiverCurrencyId",
        me?.receiverCurrency?.id || currencies[0]?.id
      );
    }
  }, [me, languages, currencies, setValue]);

  const handleSubmitSettingsForm = (data: {
    myCurrencyId: number;
    languageId: number;
    receiverCurrencyId: number;
  }) => {
    updateUser(data);
  };

  const processedExchangePairs = useMemo(() => {
    if (!exchangePairs) return [];

    return exchangePairs.reduce((acc: any[], pair: any) => {
      acc.push(pair);

      return acc;
    }, []);
  }, [exchangePairs]);

  const availableCurrencies = useMemo(() => {
    const currencyIds = new Set();
    return (
      processedExchangePairs?.reduce((currencies: any[], pair: any) => {
        if (!currencyIds.has(pair.baseCurrencyId.id)) {
          currencies.push(pair.baseCurrencyId);
          currencyIds.add(pair.baseCurrencyId.id);
        }
        if (!currencyIds.has(pair.targetCurrencyId.id)) {
          currencies.push(pair.targetCurrencyId);
          currencyIds.add(pair.targetCurrencyId.id);
        }
        return currencies;
      }, []) || []
    );
  }, [processedExchangePairs]);

  const exchangeRate = useMemo(() => {
    const selectedPair = processedExchangePairs?.find(
      (pair: any) =>
        pair.baseCurrencyId.id === myCurrencyId &&
        pair.targetCurrencyId.id === receiverCurrencyId
    );
    return selectedPair?.exchangeRate || 0;
  }, [processedExchangePairs, myCurrencyId, receiverCurrencyId]);

  return (
    <form
      onSubmit={handleSubmit(handleSubmitSettingsForm)}
      className="main settings"
    >
      <div className="body">
        <h1 className="title mb-title">{t("SETTINGS.TITLE")}</h1>
        <div className="settings__form form">
          <div className="settings__form-items form__items">
            <Controller
              name="myCurrencyId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  id="myCurrency"
                  currencies={currencies?.map((currency: any) => ({
                    id: currency.id,
                    code: currency.code,
                    name: currency.name,
                    symbol: currency.symbol,
                  }))}
                  selectedCurrency={
                    currencies?.find(
                      (currency: any) => currency?.id === value
                    ) || currencies?.[0]
                  }
                  onCurrencyChange={(item) => onChange(item?.id)}
                  label={t("TRANSFER_CURRENCY")}
                />
              )}
            />

            <Controller
              name="languageId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <LanguageDropdown
                  selectedLanguage={languages?.find(
                    (item: any) => item.id === value
                  )}
                  languages={languages}
                  handleLanguageSelect={(language) => onChange(language.id)}
                />
              )}
            />

            <Controller
              name="receiverCurrencyId"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Dropdown
                  id="receiverCurrency"
                  currencies={currencies?.map((currency: any) => ({
                    id: currency.id,
                    code: currency.code,
                    name: currency.name,
                    symbol: currency.symbol,
                  }))}
                  selectedCurrency={
                    currencies?.find(
                      (currency: any) => currency?.id === value
                    ) || currencies?.[0]
                  }
                  onCurrencyChange={(item) => onChange(item?.id)}
                  label={t("SETTINGS.RECEIVE_CURRENCY.LABEL")}
                  rightText={`${t("RATE")} ${
                    availableCurrencies?.find(
                      (item: { id: number }) => item.id === myCurrencyId
                    )?.code
                  } = ${Number(exchangeRate).toFixed(2)} ${
                    availableCurrencies?.find(
                      (item: { id: number }) => item.id === receiverCurrencyId
                    )?.code
                  }`}
                />
              )}
            />

            <Link
              to={ROUTES.SETTING_CARDS}
              className="button button--blue-border"
            >
              {t("MY_CARDS")}
            </Link>
          </div>
        </div>
      </div>
      <div className="footer">
        <button
          type="submit"
          className="button"
          disabled={!isDirty || isPending}
        >
          {t("SAVE_CHANGES")}
        </button>
      </div>
    </form>
  );
};

export default Settings;
