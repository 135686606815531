import React from "react";

const CashOrderConfirmation: React.FC = () => {
  return (
    <>
      <div className="body deal-done-cash">
        <div className="deal-done-cash__done done">
          <img className="done__icon" src="/img/svg/done.svg" alt="" />
          <p className="done__text">Ваши средства готовы к выдаче</p>
        </div>
        <ul className="deal-done-cash__details done-details">
          <li className="done-details__item">
            <img
              className="done-details__item-icon"
              src="./img/svg/location.svg"
              alt=""
            />
            <div className="done-details__item-text">
              <label htmlFor="" className="done-details__address-label">
                Адрес получения:
              </label>
              <address className="done-details__address">
                Улица Кеннеди 14, д. 15
              </address>
            </div>
          </li>
          <li className="done-details__item">
            <img
              className="done-details__item-icon"
              src="./img/svg/clock.svg"
              alt=""
            />
            <div className="done-details__item-text">
              <div className="done-details__time">
                Вы можете забрать наличные в течении{" "}
                <span className="done-details__time-value">72 часов</span>.
              </div>
              <p className="done-details__time-descr">
                После истечения срока заявка будет аннулирована
              </p>
            </div>
          </li>
        </ul>
      </div>
      <div className="footer">
        <button className="button">Отлично!</button>
      </div>
    </>
  );
};

export default CashOrderConfirmation;
