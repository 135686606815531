import React, { useEffect, useState } from "react";
import CashOrderDetails from "../CashOrderDetails";
import CashOrderConfirmation from "../CashOrderConfirmation";

const CashOrderView: React.FC = () => {
  const [status, setStatus] = useState(localStorage.getItem("ORDER_STATUS"));

  useEffect(() => {
    const onStorageChange = () => {
      setStatus(localStorage.getItem("ORDER_STATUS"));
    };

    window.addEventListener("storage", onStorageChange);
    const interval = setInterval(onStorageChange, 500);

    return () => {
      window.removeEventListener("storage", onStorageChange);
      clearInterval(interval);
    };
  }, []);

  if (status === "DETAILS") {
    return <CashOrderDetails />;
  } else if (status === "CONFIRMATION") {
    return <CashOrderConfirmation />;
  } else {
    return <div>Invalid status</div>;
  }
};

export default CashOrderView;
