import React, { useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useGetOrder } from "../../services/order.services";
import { useNavigate } from "react-router-dom";
import {
  useGetMessagesFromOrderChat,
  useReadMessage,
} from "../../services/chat.services";
import { useTelegram } from "../../providers/TelegramProvider";
import { useGetMe } from "../../services/user.services";
import { useWebSocket } from "../../contexts/WebSocketContext";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";

enum MessageSender {
  USER = "user",
  SUPPORT = "support",
  ADMIN = "admin",
}

interface Message {
  id: number;
  text: string;
  sender: MessageSender;
  senderId: number;
  senderName?: string;
  time: string;
  date: string;
  read?: boolean;
}

const OrderChat: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState("");
  const orderId = localStorage.getItem("CURRENT_ORDER_ID");
  const { order, refetch } = useGetOrder(orderId!);
  const { me } = useGetMe();
  const { messageList } = useGetMessagesFromOrderChat(orderId!, 1);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { webApp } = useTelegram();
  const { mutate: readMessage } = useReadMessage(
    () => {},
    () => {}
  );

  const { subscribe, unsubscribe, emit } = useWebSocket();

  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const chatContainerRef = useRef<HTMLDivElement>(null);

  const getSenderType = useCallback(
    (senderId: number): MessageSender => {
      if (senderId.toString() === me?.id.toString()) return MessageSender.USER;
      if (senderId.toString() === order?.trader1?.id.toString())
        return MessageSender.SUPPORT;
      return MessageSender.ADMIN;
    },
    [me?.id, order?.trader1?.id]
  );

  const markMessagesAsRead = useCallback(() => {
    const unreadMessages = messages.filter(
      (message) => message.sender === MessageSender.SUPPORT && !message.read
    );

    if (unreadMessages.length > 0) {
      unreadMessages.forEach((message) => {
        readMessage(message.id.toString());
      });

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.sender === MessageSender.SUPPORT && !msg.read
            ? { ...msg, read: true }
            : msg
        )
      );
    }
  }, [messages, readMessage]);

  useEffect(() => {
    const handleNewMessage = (data: any) => {
      const newMessage: Message = {
        id: data[0].id || Date.now(),
        text: data[0].message || data[0].chatMessage_text,
        sender: getSenderType(data[0].senderId || data[0].chatMessage_senderId),
        senderId: data[0].senderId || data[0].chatMessage_senderId,
        time: new Date(data[0].createdAt || Date.now()).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        date: new Date(data[0].createdAt || Date.now())
          .toISOString()
          .split("T")[0],
        read: false,
      };

      setMessages((prev) => {
        return [...prev, newMessage];
      });
    };

    subscribe("message", handleNewMessage);
    return () => unsubscribe("message", handleNewMessage);
  });

  useEffect(() => {
    if (messageList?.length && me) {
      const formattedMessages = messageList.map((msg: any) => ({
        id: msg.id || msg.chatMessage_id,
        text: msg.text || msg.chatMessage_message,
        sender: getSenderType(msg.senderId || msg.chatMessage_senderId),
        senderId: msg.senderId || msg.chatMessage_senderId,
        time: new Date(
          msg.createdAt || msg.chatMessage_createdAt
        ).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        date: new Date(msg.createdAt || msg.chatMessage_createdAt)
          .toISOString()
          .split("T")[0],
        read: msg.read || msg.chatMessage_read || false,
      }));

      if (messages.length === 0) {
        setMessages(formattedMessages.reverse());
      }
    }
  }, [messageList, me]);

  // Корректная навигация назад
  useEffect(() => {
    if (!webApp) return;

    const handleBackButton = () => {
      navigate(-1);
    };

    webApp.BackButton.show();
    webApp.BackButton.onClick(handleBackButton);

    return () => {
      webApp.BackButton.offClick(handleBackButton);
      webApp.BackButton.hide();
    };
  }, [webApp, navigate, orderId]);

  const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const target = e.target as HTMLDivElement;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      markMessagesAsRead();
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (inputValue.trim() && me && order) {
      const newMessage: Message = {
        id: Date.now(),
        text: inputValue,
        sender: MessageSender.USER,
        senderId: me.id,
        time: new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        date: new Date().toISOString().split("T")[0],
        read: false,
      };

      emit("sendMessage", {
        senderId: me.id,
        receiverId: [order.trader1.id],
        orderId: orderId,
        message: inputValue,
      });

      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInputValue("");
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const groupedMessages = messages.reduce<{ [date: string]: Message[] }>(
    (groups, message) => {
      const date = message.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    },
    {}
  );

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (order?.status === 8) {
      localStorage.setItem("ORDER_STATUS", "CANCELED");
    }
    if (order?.status === 11) {
      const timer = setTimeout(() => {
        refetch();
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [order, refetch]);

  const renderMessage = (message: Message) => (
    <div key={message.id} className="chat__messages">
      <div className={`chat__message chat__message--${message.sender}`}>
        {message.sender === MessageSender.ADMIN && (
          <div className="chat__message-sender">Admin</div>
        )}
        <div className="chat__message-text">{message.text}</div>
        <div className="chat__message-time">
          {message.time}
          {message.sender === MessageSender.USER && (
            <div className="chat__message-check">
              <img
                className={`chat__message-check-icon ${
                  message.read ? "read" : ""
                }`}
                src="/img/svg/check-mes.svg"
                alt=""
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  // Добавляем обработчик выбора эмодзи
  const onEmojiClick = (emojiData: EmojiClickData) => {
    setInputValue((prev) => prev + emojiData.emoji);
    setShowEmojiPicker(false);
  };

  // Добавляем обработчик клика вне эмодзи пикера
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (
        !target.closest(".emoji-picker-container") &&
        !target.closest(".chat__input-btn--emoji")
      ) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="main chat">
      <div className="body">
        <h1 className="chat__title title">
          {t("ORDER_CHAT.HEADER", {
            id: orderId,
          })}
        </h1>
        <div
          className="chat__items"
          ref={chatContainerRef}
          onScroll={handleScroll}
        >
          {Object.keys(groupedMessages).map((date) => (
            <div key={date}>
              <div className="chat__date">{date}</div>
              {groupedMessages[date].map((message) => renderMessage(message))}
            </div>
          ))}
        </div>

        <form className="chat__input-container" onSubmit={handleSubmit}>
          <div
            className="emoji-picker-container"
            style={{ position: "relative" }}
          >
            <button
              type="button"
              className="chat__input-btn chat__input-btn--emoji"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            >
              <img
                className="chat__input-icon chat__input-icon--emoji"
                src="/img/svg/smile.svg"
                alt=""
              />
            </button>
            {showEmojiPicker && (
              <div
                style={{
                  position: "absolute",
                  bottom: "100%",
                  left: "0",
                  zIndex: 1000,
                  marginBottom: "10px",
                }}
              >
                <EmojiPicker
                  onEmojiClick={onEmojiClick}
                  autoFocusSearch={false}
                  width={300}
                  height={400}
                />
              </div>
            )}
          </div>
          <textarea
            className="chat__input"
            placeholder="Start typing..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleKeyDown} // Добавляем обработчик
          ></textarea>
          <button type="button" className="chat__input-btn">
            <img
              className="chat__input-icon chat__input-icon--mention"
              src="/img/svg/mention.svg"
              alt=""
            />
          </button>
          <button type="submit" className="chat__input-btn">
            <svg className="chat__input-icon chat__input-icon--send">
              <use xlinkHref="/img/sprite.svg#send"></use>
            </svg>
          </button>
        </form>
      </div>
    </div>
  );
};

export default OrderChat;
