import React, { useEffect, useState } from "react";
import Main from "../Main";
import OrderPending from "../Main/OrderPending";
import SendOrderAmount from "../SendOrderAmount";
import OrderTransferWaiting from "../OrderTransferWaiting";
import TraderPaid from "../TraderPaid";
import OrderCompleted from "../OrderCompleted";
import OrderChat from "../OrderChat";

const OrderView: React.FC = () => {
  const [status, setStatus] = useState(localStorage.getItem("ORDER_STATUS"));

  useEffect(() => {
    const onStorageChange = () => {
      setStatus(localStorage.getItem("ORDER_STATUS"));
    };

    window.addEventListener("storage", onStorageChange);

    const interval = setInterval(onStorageChange, 500);

    return () => {
      window.removeEventListener("storage", onStorageChange);
      clearInterval(interval);
    };
  }, []);

  if (status === "PENDING") {
    return <OrderPending />;
  } else if (status === "TRADER_FOUND") {
    return <SendOrderAmount />;
  } else if (status === "CUSTOMER_SENT") {
    return <OrderTransferWaiting />;
  } else if (status === "TRADER_PAID") {
    return <TraderPaid />;
  } else if (status === "ORDER_COMPLETED") {
    return <OrderCompleted />;
  } else if (status === "DISPUTE") {
    return <OrderChat />;
  } else if (status === "CANCELED") {
    return <OrderCompleted />;
  } else {
    return <Main />;
  }
};

export default OrderView;
