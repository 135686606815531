import React, { useState, useEffect, useRef, useCallback } from "react";
import { useWebSocket } from "../../contexts/WebSocketContext";
import { useGetMe } from "../../services/user.services";
import {
  useCloseSupportChat,
  useGetMessagesFromSupportChat,
  useReadMessage,
} from "../../services/chat.services";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import Picker from "emoji-picker-react";
import { ROUTES } from "../../routes/routes";

interface Message {
  id: number;
  text: string;
  sender: "user" | "support";
  time: string;
  date: string;
  read?: boolean;
}

const Support: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [ticketId, setTicketId] = useState<string>("");
  const [currentPage] = useState(1);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const { me } = useGetMe();
  const { t } = useTranslation();
  const { messageList } = useGetMessagesFromSupportChat(ticketId, currentPage);
  const { mutate: readMessage } = useReadMessage(
    () => {},
    () => {}
  );

  const { subscribe, unsubscribe, emit } = useWebSocket();
  const navigate = useNavigate();

  const adjustTextareaHeight = (element: HTMLTextAreaElement) => {
    element.style.height = "auto";
    element.style.height = `${element.scrollHeight}px`;
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(e.target.value);
    adjustTextareaHeight(e.target);
  };

  const { mutate: closeSupportChat } = useCloseSupportChat(
    () => {
      localStorage.removeItem("chatId");
      setTicketId("");
      navigate(ROUTES.MAIN);
      toast.success(t("CHAT_CLOSED"));
    },
    () => {
      toast.error(t("SOMETHING_WENT_WRONG"));
    }
  );

  useEffect(() => {
    let currentChatId = localStorage.getItem("chatId");
    if (!currentChatId) {
      currentChatId = uuidv4();
      localStorage.setItem("chatId", currentChatId!);
    }
    setTicketId(currentChatId!);
  }, []);

  const markMessagesAsRead = useCallback(() => {
    const unreadMessages = messages.filter(
      (message) => message.sender === "support" && !message.read
    );

    if (unreadMessages.length > 0) {
      const messageIds = new Set();
      unreadMessages.forEach((message) => {
        if (!messageIds.has(message.id)) {
          messageIds.add(message.id);
          readMessage(message.id.toString());
        }
      });

      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          messageIds.has(msg.id) ? { ...msg, read: true } : msg
        )
      );
    }
  }, [messages, readMessage]);

  const scrollToBottom = useCallback(
    (smooth = true) => {
      if (messagesEndRef.current && autoScrollEnabled) {
        messagesEndRef.current.scrollIntoView({
          behavior: smooth ? "smooth" : "auto",
          block: "end",
        });
      }
    },
    [autoScrollEnabled]
  );

  const handleScroll = useCallback(() => {
    if (!chatContainerRef.current) return;

    const { scrollTop, scrollHeight, clientHeight } = chatContainerRef.current;
    const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;

    setAutoScrollEnabled(isNearBottom);
  }, []);

  useEffect(() => {
    scrollToBottom(false);
  }, [messageList, scrollToBottom]);

  useEffect(() => {
    const chatContainer = chatContainerRef.current;
    if (chatContainer) {
      chatContainer.addEventListener("scroll", handleScroll);
      return () => chatContainer.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  useEffect(() => {
    if (!me) return;

    const handleMessage = (message: any) => {
      const newMessage = {
        id: message[0].id,
        text: message[0].message,
        sender: message[0].senderId === me.id ? "user" : "support",
        time: new Date(message[0].createdAt).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        date: message[0].createdAt.split("T")[0],
        read: message[0].read,
      };

      setMessages((prevMessages: any) => {
        const messageExists = prevMessages.some(
          (msg: any) => msg.id === newMessage.id
        );
        if (!messageExists) {
          setTimeout(() => scrollToBottom(), 100);
          return [...prevMessages, newMessage];
        }
        return prevMessages;
      });
    };

    subscribe("message", handleMessage);
    return () => unsubscribe("message", handleMessage);
  }, [me, subscribe, unsubscribe, scrollToBottom]);

  useEffect(() => {
    if (messages.length > 0) return;

    if (messageList && messageList.length > 0 && me) {
      const loadedMessages = messageList.map((msg: any) => ({
        id: msg.chatMessage_id,
        text: msg.chatMessage_message,
        sender: msg.chatMessage_senderId === me.id ? "user" : "support",
        time: new Date(msg.chatMessage_createdAt).toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        }),
        date: msg.chatMessage_createdAt.split("T")[0],
        read: msg.chatMessage_read,
      }));

      setMessages(loadedMessages as any);
    }
  }, [messageList, me]);

  useEffect(() => {
    const unreadSupportMessages = messages.filter(
      (message) => message.sender === "support" && !message.read
    );

    if (unreadSupportMessages.length > 0) {
      markMessagesAsRead();
    }
  }, [messages, markMessagesAsRead]);

  const handleSubmit = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();

      if (inputValue.trim() && me) {
        const tempId = Date.now();
        const newMessage: Message = {
          id: tempId,
          text: inputValue,
          sender: "user",
          time: new Date().toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          }),
          date: new Date().toISOString().split("T")[0],
          read: false,
        };

        setMessages((prevMessages) => [...prevMessages, newMessage]);

        emit("sendMessageToSupport", {
          senderId: me.id,
          receiverId: "support",
          ticketId: ticketId,
          message: inputValue,
        });

        setInputValue("");
        setAutoScrollEnabled(true);
        setTimeout(() => scrollToBottom(), 100);

        const textarea = document.querySelector(
          ".chat__input"
        ) as HTMLTextAreaElement;
        if (textarea) {
          textarea.style.height = "auto";
        }
      }
    },
    [inputValue, emit, me, ticketId, scrollToBottom]
  );

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleSubmit(e);
      }
    },
    [handleSubmit]
  );

  const handleEndChat = useCallback(() => {
    closeSupportChat(ticketId);
  }, [closeSupportChat, ticketId]);

  const groupedMessages = messages.reduce<{ [date: string]: Message[] }>(
    (groups, message) => {
      const date = message.date;
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    },
    {}
  );

  return (
    <div
      className="main chat"
      style={{
        height: messages.length > 0 ? "82vh" : "100%",
        overflowY: "hidden",
      }}
    >
      <div className="body" style={{ position: "relative", height: "100%" }}>
        <h1 className="chat__title title">{t("SUPPORT_CHAT")}</h1>
        <div className="chat__items" ref={chatContainerRef}>
          {Object.keys(groupedMessages).length > 0 ? (
            Object.keys(groupedMessages).map((date) => (
              <div key={date}>
                <div className="chat__date">{date}</div>

                {groupedMessages[date].map((message) => (
                  <div key={message.id} className="chat__messages">
                    <div
                      className={`chat__message chat__message--${message.sender}`}
                    >
                      <div className="chat__message-text">{message.text}</div>
                      <div className="chat__message-time">
                        {message.time}
                        {message.sender === "user" && (
                          <div className="chat__message-check">
                            {message.read ? (
                              <img
                                className={`chat__message-check-icon ${
                                  message.read ? "read" : ""
                                }`}
                                src="/img/svg/check-mes2.svg"
                                alt=""
                              />
                            ) : (
                              <img
                                className={`chat__message-check-icon ${
                                  message.read ? "read" : ""
                                }`}
                                src="/img/svg/check-mes.svg"
                                alt=""
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <div className="chat__empty">{t("SUPPORT_CHAT_EMPTY")}</div>
          )}
          <div ref={messagesEndRef} />
        </div>

        <form className="chat__input-container" onSubmit={handleSubmit}>
          <button
            type="button"
            className="chat__input-btn"
            onClick={() => setShowEmojiPicker((prev) => !prev)}
          >
            <img
              className="chat__input-icon chat__input-icon--emoji"
              src="/img/svg/smile.svg"
              alt=""
            />
          </button>
          <textarea
            className="chat__input"
            placeholder={t("FORM.MESSAGE.PLACEHOLDER")}
            value={inputValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            rows={1}
          />
          <button
            type="button"
            onClick={() => setInputValue((prev) => prev + "@")}
            className="chat__input-btn"
          >
            <img
              className="chat__input-icon chat__input-icon--mention"
              src="/img/svg/mention.svg"
              alt=""
            />
          </button>
          <button type="submit" className="chat__input-btn">
            <svg className="chat__input-icon chat__input-icon--send">
              <use xlinkHref="/img/sprite.svg#send"></use>
            </svg>
          </button>
        </form>

        {showEmojiPicker && (
          <div className="emoji-picker">
            <Picker
              onEmojiClick={(emojiObject: any) => {
                setInputValue((prev) => prev + emojiObject.emoji);
                setShowEmojiPicker(false);
              }}
              width={300}
              height={300}
              previewConfig={{ showPreview: false }}
            />
          </div>
        )}

        {messages.length > 0 && (
          <div className="footer chat__actions">
            <button
              className="chat__button chat__button--reject button button--gray-border"
              onClick={handleEndChat}
            >
              {t("ISSUE_IS_NOT_RESOLVED")}
            </button>
            <button className="chat__button button" onClick={handleEndChat}>
              {t("ISSUE_RESOLVED")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Support;
