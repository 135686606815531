import React, { useState, useEffect, useMemo } from "react";
import {
  useGetAddresses,
  useGetCities,
  useGetCountries,
} from "../../services/countries.services";
import CountryDropdown from "../../components/Form/CountryDropdown";
import CityDropdown from "../../components/Form/CityDropdown";

interface Address {
  id: number;
  fullAddress: string;
  user: {
    id: number;
    myCurrency: { id: number; code: string } | null;
    role: { id: number; name: string; __entity: string };
    status: { id: number; name: string; __entity: string };
    language: {
      id: number;
      code: string;
      name: string;
      file_path: string;
    } | null;
    __entity: string;
  };
  currency: null;
}

interface Country {
  id: number;
  country: string;
}

interface City {
  id: number;
  city: string;
}

const CashOrderDetails: React.FC = () => {
  const { countries } = useGetCountries();
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [selectedCity, setSelectedCity] = useState<City | null>(null);
  const [selectedAddress, setSelectedAddress] = useState<Address | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { cities, refetch } = useGetCities(selectedCountry?.id.toString()!);

  const { addresses } = useGetAddresses();

  const handleCountryChange = (country: any) => {
    setSelectedCountry(country);
  };

  const handleCityChange = (city: any) => {
    setSelectedCity(city);
  };

  const handleAddressChange = (address: Address) => {
    setSelectedAddress(address);
  };

  useEffect(() => {
    if (countries?.length! > 0) {
      setSelectedCountry(countries![0]);
    }
  }, [countries]);

  useEffect(() => {
    if (selectedCountry) {
      refetch();
    }
  }, [selectedCountry, refetch]);

  useEffect(() => {
    if (cities) {
      setSelectedCity(cities![0]);
    }
  }, [cities]);

  const filteredAddresses = useMemo(
    () =>
      addresses?.filter((addr: any) => {
        const [country, city] = addr?.fullAddress
          ?.split(", ")
          .map((s: any) => s?.trim());
        return (
          country === selectedCountry?.country && city === selectedCity?.city
        );
      }),
    [addresses, selectedCountry, selectedCity]
  );

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!selectedAddress) return;

    try {
      setIsSubmitting(true);
      localStorage.setItem("ORDER_STATUS", "CONFIRMATION");
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form className="main entering-details-cash" onSubmit={handleSubmit}>
      <div className="body">
        <h1 className="title mb-title">Детали перевода</h1>
        <div className="entering-details-cash__form form">
          <div className="entering-details-cash__form-items form__items">
            <CountryDropdown
              id="country-select"
              selectedCountry={selectedCountry}
              onCountryChange={handleCountryChange}
              countries={countries}
              label="Страна"
            />

            <CityDropdown
              id="city-select"
              selectedCity={selectedCity}
              onCityChange={handleCityChange}
              cities={cities}
              label="Город"
            />
          </div>

          {selectedCountry && selectedCity && (
            <div className="entering-details-cash__address">
              <h2 className="entering-details-cash__address-title">
                Выберите пункт выдачи
              </h2>
              <div className="options options--address">
                {filteredAddresses?.map((address: any) => (
                  <div className="options__item" key={address.id}>
                    <input
                      id={`address${address.id}`}
                      type="radio"
                      name="address"
                      className="options__input"
                      checked={selectedAddress?.id === address.id}
                      onChange={() => handleAddressChange(address)}
                    />
                    <label
                      htmlFor={`address${address.id}`}
                      className="options__label options__label--address"
                    >
                      {address.fullAddress}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="footer">
        <button
          type="submit"
          className="button"
          disabled={!selectedAddress || isSubmitting}
        >
          {isSubmitting ? "Загрузка..." : "Далее"}
        </button>
      </div>
    </form>
  );
};

export default CashOrderDetails;
